import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker without MQTT",
  "path": "/Frequently_Asked_Question/ioBroker_without_MQTT/",
  "dateChanged": "2020-01-25",
  "author": "Mike Polinowski",
  "excerpt": "How can I use my 720p camera with ioBroker?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - ioBroker without MQTT' dateChanged='2020-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use my 720p camera with ioBroker?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_without_MQTT/' locationFR='/fr/Frequently_Asked_Question/ioBroker_without_MQTT/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Q`}</strong>{`: Hello, I have seen your very extensive documentation for IoBroker. But I am still unable to display the live stream of the IN-7011-HD and the IN-5905-HD. Furthermore I want to set up two buttons that allow me to pan&tilt my cameras between preset positions.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/" mdxType="Link">{`This part of the ioBroker manual`}</Link>{` is actually only for Full HD cameras as it requires an `}<Link to="/Web_User_Interface/1080p_Series/Network/MQTT/" mdxType="Link">{`MQTT Broker`}</Link>{`. But the integration of the camera livestream also works with your 7011 and 5905:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/#live-video" mdxType="Link"><EuiButton fill mdxType="EuiButton">Live Video einbinden</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The video can be embedded directly via the snapshot or MJPEG stream path - it is recommended to use the middle, medium resolution (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/12`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`auto`}</code>{`) stream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/b12f7/ioBroker_und_INSTAR_CGI_Befehle_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACH0lEQVQozz3C3WsSAQAA8AtqRK8FPcYeHDSna0FQENFD/0APkYyeo4j24EuL1epB21aamjCzTawZZPuCYNZ0rUXU5qY+nPNjuo/7/v5w3u707jp3YUE/fkCxWEAQFMMwhmEURdE0TZIkWZZ5nlcaTfMvw2iJYk0QBALHeJ7nOQ6BYZIggGq1SuA4Q7eFQiG3y+31+vyB4Lg/8O7NROxtNOjxLH1bqWxvb1WrCI7jJElzbFPXFbUJUAwvyUpdVhAU7bJYjgDAmc5O+/m+a1ev3Oq/cfnShYvW7rGXXnB1bRcEeQTlYJhvRzgIAnYIlj9oELUGtgfZbbZu69nhF67h58MPR4Y+JOcGnPem5+O5jZ/lhYQE5sVsllpdY9PrzEaGWlwC1mEOz22id52Ya9Rqt1l7eqKfPnomfdH598n0ytDjwem5OFjK70J7vCjuNzTNaLVM818gWSBoQZJJGi1t2Xptp0+d9L4ORmfjwfD4yNizwUeD/lAg+X25XC4LHCc2TN0wTfPQPGwHFvMoKcpiU4dQ9Fxf74njHQ7HzQGn8+moeyb1eSaV8IVfhacmIAhSVXVfMYyW+R/wq4KjXB1mJZqiLF2WYx1Hr/c7vJHIQjqzI0jzKz8euJ7cvn+nWCppqlo7UJua/ltv0zQNgGkOYeupCqfIciw2NRGZTC5/zRVKlFCnRSmT30ykvkzPzmSzWRAE92CUJCmGoVmWYxjmD2PNkniG61B8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a410bf9b2c1a4de3ae02fd361010f69/e4706/ioBroker_und_INSTAR_CGI_Befehle_00.avif 230w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/d1af7/ioBroker_und_INSTAR_CGI_Befehle_00.avif 460w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/7f308/ioBroker_und_INSTAR_CGI_Befehle_00.avif 920w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/2c8af/ioBroker_und_INSTAR_CGI_Befehle_00.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a410bf9b2c1a4de3ae02fd361010f69/a0b58/ioBroker_und_INSTAR_CGI_Befehle_00.webp 230w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/bc10c/ioBroker_und_INSTAR_CGI_Befehle_00.webp 460w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/966d8/ioBroker_und_INSTAR_CGI_Befehle_00.webp 920w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/52c2b/ioBroker_und_INSTAR_CGI_Befehle_00.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a410bf9b2c1a4de3ae02fd361010f69/81c8e/ioBroker_und_INSTAR_CGI_Befehle_00.png 230w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/08a84/ioBroker_und_INSTAR_CGI_Befehle_00.png 460w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/c0255/ioBroker_und_INSTAR_CGI_Befehle_00.png 920w", "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/b12f7/ioBroker_und_INSTAR_CGI_Befehle_00.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a410bf9b2c1a4de3ae02fd361010f69/c0255/ioBroker_und_INSTAR_CGI_Befehle_00.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3><u>MJPEG Stream URL</u></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://IP-Address:Port/mjpegstream.cgi?-chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://IP-Address:Port/mjpegstream.cgi?-chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <h3><u>JPG URL</u></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://IP-Address:Port/tmpfs/snap.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://IP-Address:Port/tmpfs/auto.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://IP-Address:Port/tmpfs/auto2.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`To pan between preset positions you have to send the corresponding CGI commands to the camera:`}</p>
    <Link to="/Software/Home_Automation/IOBroker/#3-anfahren-verschiedener-positionen-nach-einem-zeitplan" mdxType="Link"><EuiButton fill mdxType="EuiButton">Moving between camera positions</EuiButton></Link>
    <p>{`To control your camera with CGI command, we need to be able to trigger a HTTP GET request in ioBroker. One way to do this is with the Javascript Engine and `}<Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/" mdxType="Link">{`Blockly`}</Link>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2221b6dec20b641b58883d893803ebc6/4cdf7/ioBroker_und_INSTAR_CGI_Befehle_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeElEQVQoz4XNvUsCcRzH8dv6C5pr6i+ooa2puaGlMYjACMIoC9paaoggoa0l7IGiIHrwMYMsCAXPUzz1zusePFPL+/2+Pz3PO0/qgqKhhnzzWj98qIHh8d2zO+9RYHLjaiVqeCL6YsTcDCtBWgyn1RCjRhjlOqkuhZtzAWM+0FoItZZvDXe4veZ/ofr6B92be4tbh66dq/X71uwlrAbrIbauVjXxlYgpVonGKkymnMpISlWuYbmKvkkVRA0Nj41MzIxOLXu2D1z79PQBf1HosDWTezMJITqgJtZ0gnXAOgG98QuVyxcEQUD1V6NJpr3+tfNMXHNOuXdf1q5pAAQwkB/wB3XCYD9v3ojOU9nOcmKSFXLPJaao+mkFIQT/RkULWkwgj0onWzXVkiJJoiQ+qyW5Vi71HgNCzQZxnI/3rs1xXDyRYNJpNpfniwLuPW60ENG7jtOxbZ7nUzSdZjJcgZNlufezZVpgWobvuP3wCJZFMAYA/BX06hM7G8tx7R34VQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2221b6dec20b641b58883d893803ebc6/e4706/ioBroker_und_INSTAR_CGI_Befehle_01.avif 230w", "/en/static/2221b6dec20b641b58883d893803ebc6/d1af7/ioBroker_und_INSTAR_CGI_Befehle_01.avif 460w", "/en/static/2221b6dec20b641b58883d893803ebc6/7f308/ioBroker_und_INSTAR_CGI_Befehle_01.avif 920w", "/en/static/2221b6dec20b641b58883d893803ebc6/5075a/ioBroker_und_INSTAR_CGI_Befehle_01.avif 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2221b6dec20b641b58883d893803ebc6/a0b58/ioBroker_und_INSTAR_CGI_Befehle_01.webp 230w", "/en/static/2221b6dec20b641b58883d893803ebc6/bc10c/ioBroker_und_INSTAR_CGI_Befehle_01.webp 460w", "/en/static/2221b6dec20b641b58883d893803ebc6/966d8/ioBroker_und_INSTAR_CGI_Befehle_01.webp 920w", "/en/static/2221b6dec20b641b58883d893803ebc6/157c9/ioBroker_und_INSTAR_CGI_Befehle_01.webp 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2221b6dec20b641b58883d893803ebc6/81c8e/ioBroker_und_INSTAR_CGI_Befehle_01.png 230w", "/en/static/2221b6dec20b641b58883d893803ebc6/08a84/ioBroker_und_INSTAR_CGI_Befehle_01.png 460w", "/en/static/2221b6dec20b641b58883d893803ebc6/c0255/ioBroker_und_INSTAR_CGI_Befehle_01.png 920w", "/en/static/2221b6dec20b641b58883d893803ebc6/4cdf7/ioBroker_und_INSTAR_CGI_Befehle_01.png 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2221b6dec20b641b58883d893803ebc6/c0255/ioBroker_und_INSTAR_CGI_Befehle_01.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After you have installed the Blockly Adapter, click on `}<strong parentName="p">{`Scripts`}</strong>{` in the lower left corner. In the following window you can add a new script with the `}<strong parentName="p">{`+`}</strong>{` symbol - select `}<strong parentName="p">{`Blockly`}</strong>{` here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/1628f/ioBroker_und_INSTAR_CGI_Befehle_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlklEQVQoz42OS0/bQBSF/StZsuuKBbApD7EBVSpQRCkCpKpqRUQkHkECAkKpSngmPLJA0BgDcUhCSJOMB4899njGnmRslAQQAhY9+jZXOp/ukVwIryLLa+tRRf4rarzusdT5NTItiExwb+jY5rUa59xtxrZthBCAOka3lDrSyuZh78evXSOhzs+hvsmlrpHwYjSey6pVAIhNOGEOwhSTejOE2LqulwC09GvPZZLluIBwZDFo0grEmklTidPE3r5yeQnL1YMfK6t9U/sTi8GbCCEkX4jn2/f9IAguzuSd7W1ZUbS7SmzoZ7h98M+XMKtz5rnUfYR5jNdrkhDC94MWQjTktJI+PjrKFfLg9l+s//vSh+Gd6Qh0sGbooEkV3WvYQBZuyK8+n6blZDKRyapaqRIb+rXQMbo7sfBycKtGCHlHTp+dp05O8oWCVqrEv81Husfjo+H/lTPy1WEyqd5kQbG82TMz1zbwe2yuMdt8mm3o0DKhid6Ri8WimlFz+Ry4K299mo32Th+HNih3HUZbEEqp5xoYPwBTP97dU4CJ5AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b309c14d38ff914eeea96b6c24ad7bc0/e4706/ioBroker_und_INSTAR_CGI_Befehle_02.avif 230w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/d1af7/ioBroker_und_INSTAR_CGI_Befehle_02.avif 460w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/7f308/ioBroker_und_INSTAR_CGI_Befehle_02.avif 920w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/a0c3b/ioBroker_und_INSTAR_CGI_Befehle_02.avif 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b309c14d38ff914eeea96b6c24ad7bc0/a0b58/ioBroker_und_INSTAR_CGI_Befehle_02.webp 230w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/bc10c/ioBroker_und_INSTAR_CGI_Befehle_02.webp 460w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/966d8/ioBroker_und_INSTAR_CGI_Befehle_02.webp 920w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/46ece/ioBroker_und_INSTAR_CGI_Befehle_02.webp 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b309c14d38ff914eeea96b6c24ad7bc0/81c8e/ioBroker_und_INSTAR_CGI_Befehle_02.png 230w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/08a84/ioBroker_und_INSTAR_CGI_Befehle_02.png 460w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/c0255/ioBroker_und_INSTAR_CGI_Befehle_02.png 920w", "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/1628f/ioBroker_und_INSTAR_CGI_Befehle_02.png 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b309c14d38ff914eeea96b6c24ad7bc0/c0255/ioBroker_und_INSTAR_CGI_Befehle_02.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the screenshot above you can already see the complete script on the right. It consists of 2 blocks for each command you want to send to the camera: a trigger block (this is the object that we can address with a button from the visualization - or automate using another script). And an action block that triggers the HTTP request to send the CGI command to the camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/1628f/ioBroker_und_INSTAR_CGI_Befehle_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPklEQVQoz02Qa2/SABSG+YP+DI2J8YtLFhP1g0YTNd6yqTFGk6mZu8nc2JgR2Bh0ZYVyKTAZt5YWVlpgFDZYXGDwGEk0O8nz4SRv3ve8xyVlVPpnfUz7mEa7i9M/44IxF+MRw8GAwWCAlNU47fWwW20ax12anVN+D4cMRxecn5//p9/v49I0naNaDafjYBkWhlLCSOSoFnTs4y71RotazUQrqdj1Bg2riVmuUsuptKr1SVC/12c0GuE4Dq7dQIhoXMZomETcOyzcfI576iXex58oFDXy5Qri1w0iHxdJfl5FnvewPjXL3JVpPA8/kFLzmA0bxjAej3HtugME1rzEtvdI/Qgjzm8irfqRfSKGZWPXLZKRFOFwGCWdppjNEZxdIjC7iDC3TiGVpdPt8G9c0pKb+MoGBx4fqc0ggZkF1u+8I/LoLTXvFpWjGilZZk8UyRcLlH7l2X27guf2G/zv3WQ1FcMyaXUdml0HV1ASSSQT1JoWsmeH5VsvWJ5+zf71u9ivZtAbNtGkTDQapWLo5OQ03288Y+HqE3au3UO9/wCjatDunXDc7eDaDgkoySSaoaP4xUmN/W8/iQej6JaNaZqIsTiCIJAv5KmoGpmgRHw7QnrNh+r10XGcS5VDAplMBn2SriAsbaEEJbL7CfSSilmvk4jFURQFTatMdOXDIrlEmqyscJgr4lz+YVJKIMsylerfCyP4n35BCUUnhmpZRTcMFCmBIIQpFIqTPZ86ILMXJx2OkU8fYNoW7ZPuhD8GG21y19YLggAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/e4706/ioBroker_und_INSTAR_CGI_Befehle_03.avif 230w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/d1af7/ioBroker_und_INSTAR_CGI_Befehle_03.avif 460w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/7f308/ioBroker_und_INSTAR_CGI_Befehle_03.avif 920w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/a0c3b/ioBroker_und_INSTAR_CGI_Befehle_03.avif 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/a0b58/ioBroker_und_INSTAR_CGI_Befehle_03.webp 230w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/bc10c/ioBroker_und_INSTAR_CGI_Befehle_03.webp 460w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/966d8/ioBroker_und_INSTAR_CGI_Befehle_03.webp 920w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/46ece/ioBroker_und_INSTAR_CGI_Befehle_03.webp 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/81c8e/ioBroker_und_INSTAR_CGI_Befehle_03.png 230w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/08a84/ioBroker_und_INSTAR_CGI_Befehle_03.png 460w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/c0255/ioBroker_und_INSTAR_CGI_Befehle_03.png 920w", "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/1628f/ioBroker_und_INSTAR_CGI_Befehle_03.png 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2be70bc7f32d6ab04af38fa429c9ba5a/c0255/ioBroker_und_INSTAR_CGI_Befehle_03.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The two commands needed to pan the camera between position 1 and 2 are:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.117:80/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.2.117:80/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`for a camera with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{`, the HTTP port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`80`}</code>{` and an admin login `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{`.`}</p>
    <p>{`What we are still missing, however, is the object that we want to switch via our Vis button and use it as a trigger to send the command:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/705cc/ioBroker_und_INSTAR_CGI_Befehle_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuElEQVQY0zXGvW7TQBwAcI+8AC/AwlPwAoiFhY8JVqZSQSmRkBgYytKKCYknQIqqTrSiCQUpVSUSEieO2tp1Yzt27Ph85/vf/z5S0VDpkFCRfsPPuX3n/udW792nnUcfuysHV8/21OpXub4vXh8tGy1438GNjlxv6xdt87Kl19pmraUa3+Srlnyzmzs3bt560ti8t/phc/voML3cD7CXmR9jsrvVPDhTYpbzfCaUFgIFShDIQcxJVbGa0Nq5++Dp45W3D59v7H3v2N+1QbEwsggD3mzSPKuSCOa50UohGIVSgJZolLzQSgpwOJrlnytrLSqIs7CgEBfV8Gw6RRXnZUpYzqCgvGC8YDC/PswIzUriRHEiBFhrGcLpLDmvtV/huIAEFpFYRFxPuI7ATP7lP3POcEK4sz2CL77ZCZanpcE4iPqD0vdJ4MeuOzs+IX5QHJ+kngdxgtMppmkdhjwMIY5pljndRI7Ly2FlpwyZ95N0+6Tvpoe9sveL9t3aHbGBW/UHfOSBNwZvzN0RHwy5O6zixLkwSkthrV0YResCzYIrNa95SWlRVWXNOKLQGpTiUoJS16SkjP0FAEqWWFObxekAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6613d43cd3ab95bfa2bd61272fa0f402/e4706/ioBroker_und_INSTAR_CGI_Befehle_04.avif 230w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/d1af7/ioBroker_und_INSTAR_CGI_Befehle_04.avif 460w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/7f308/ioBroker_und_INSTAR_CGI_Befehle_04.avif 920w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/73ad9/ioBroker_und_INSTAR_CGI_Befehle_04.avif 1071w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6613d43cd3ab95bfa2bd61272fa0f402/a0b58/ioBroker_und_INSTAR_CGI_Befehle_04.webp 230w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/bc10c/ioBroker_und_INSTAR_CGI_Befehle_04.webp 460w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/966d8/ioBroker_und_INSTAR_CGI_Befehle_04.webp 920w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/83380/ioBroker_und_INSTAR_CGI_Befehle_04.webp 1071w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6613d43cd3ab95bfa2bd61272fa0f402/81c8e/ioBroker_und_INSTAR_CGI_Befehle_04.png 230w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/08a84/ioBroker_und_INSTAR_CGI_Befehle_04.png 460w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/c0255/ioBroker_und_INSTAR_CGI_Befehle_04.png 920w", "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/705cc/ioBroker_und_INSTAR_CGI_Befehle_04.png 1071w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6613d43cd3ab95bfa2bd61272fa0f402/c0255/ioBroker_und_INSTAR_CGI_Befehle_04.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"system.adapter.admin.0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"system.user.admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1580028887973`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"common"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Go2PositionButton"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"role"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Fahre Kamera auf Position"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"boolean"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"desc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Manuell erzeugt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"states"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false:no;true:yes"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"def"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"native"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"acl"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"object"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1638`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"owner"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"system.user.admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ownerGroup"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"system.group.administrator"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"state"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1638`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"javascript.0.scriptEnabled.common.Go2Position"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"state"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To do this, go to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`javascript.0`}</code>{` / `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scriptEnabled`}</code>{` / `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`common`}</code>{` in the ioBroker object tree and click on the `}<strong parentName="p">{`+`}</strong>{` symbol at the top to add a new object - in the screenshot we named it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Go2PositionButton`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "889px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e463566b3ca1c555cb3435152620d26a/83e77/ioBroker_und_INSTAR_CGI_Befehle_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAByklEQVQoz42QXWvbMBRA/f8ZbH3Zyz7KXgdjbBQ2KJQRNjIKcds1KVmT0NiWbNmWpSvpSo5lF4bcffRpCRzEQeIg6UaTjX4RuzeX7nVsX81GYjy+COvLWeA4ts/P8ekUj6b4bORoik++4dsfJkoZn9+Viy27Jc2GqUfAX18zWOXylso1gwdWBSSljEqW59mW0aSpCi0bLbga0fKfaMmhqVRTITRGjggOoo7QaGMdmNa5Fq1TaK1zGq3UFq0zNojBgNROo7NupG3RtVGjXak7hTvvfd/3Xefvh36387b1ve+9D+K74K71XeeHvh+GYXC2EyL6fCM/XAusCUm2jKZ5uhV1hUqiEgYCjwVBhDdbY+ZXePopAgkgpWr4eCxNyPahla2ZJUlkNTgDLM/KglgN+0sQGrW5ucYvpxEqQC0lr6CpleCHxIga79YYn4fYaqgKSrOkYtQcEltjVkvz/evvWPBa8Orh2/tjozDPcP3zT8xIQVJeMjzw5uXCTM7CwIyCFeGMEkaJluKQaSOjuN2EWAHMCQhe8qo0IKyG/6Gk3Tm8nJmP70KsFawLKGnGaFoxuj92iMsFTs4iDFuyYTTPkoKkh958FZuT978Abxt1JhLMUXAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e463566b3ca1c555cb3435152620d26a/e4706/ioBroker_und_INSTAR_CGI_Befehle_05.avif 230w", "/en/static/e463566b3ca1c555cb3435152620d26a/d1af7/ioBroker_und_INSTAR_CGI_Befehle_05.avif 460w", "/en/static/e463566b3ca1c555cb3435152620d26a/83101/ioBroker_und_INSTAR_CGI_Befehle_05.avif 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e463566b3ca1c555cb3435152620d26a/a0b58/ioBroker_und_INSTAR_CGI_Befehle_05.webp 230w", "/en/static/e463566b3ca1c555cb3435152620d26a/bc10c/ioBroker_und_INSTAR_CGI_Befehle_05.webp 460w", "/en/static/e463566b3ca1c555cb3435152620d26a/ade25/ioBroker_und_INSTAR_CGI_Befehle_05.webp 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e463566b3ca1c555cb3435152620d26a/81c8e/ioBroker_und_INSTAR_CGI_Befehle_05.png 230w", "/en/static/e463566b3ca1c555cb3435152620d26a/08a84/ioBroker_und_INSTAR_CGI_Befehle_05.png 460w", "/en/static/e463566b3ca1c555cb3435152620d26a/83e77/ioBroker_und_INSTAR_CGI_Befehle_05.png 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e463566b3ca1c555cb3435152620d26a/83e77/ioBroker_und_INSTAR_CGI_Befehle_05.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in Blockly we can now add the object as a trigger in the trigger block (see screenshot above):`}</p>
    <p><em parentName="p">{`If object Go2PositionButton is true and if object Go2PositionButton is false.`}</em></p>
    <p>{`You can also import the entire Blockly script directly into Blockly using this XML code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`xml`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`xmlns`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`http://www.w3.org/1999/xhtml`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`block`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`on`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`}sZ[Gu6.@+**i9RtTMF~`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`x`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`38`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`y`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`38`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`OID`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`javascript.0.scriptEnabled.common.Go2Position`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`CONDITION`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`true`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`ACK_CONDITION`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`false`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`statement`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`STATEMENT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`block`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`request`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`HTcoI.2lpt8Uk~uHupVd`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`mutation`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`with_statement`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`false`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`mutation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`WITH_STATEMENT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`FALSE`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`LOG`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`value`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`URL`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`shadow`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`3L#W^a!.54;zZ11x9V\`$`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`TEXT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`http://192.168.2.117/param.cgi?cmd=preset`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-act=goto`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-number=1`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-usr=admin`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-pwd=instar`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`shadow`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`block`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`statement`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`next`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`block`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`on`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`W?n?{EVM(%1@~l6@8a7e`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`OID`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`javascript.0.scriptEnabled.common.Go2Position`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`CONDITION`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`false`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`ACK_CONDITION`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`false`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`statement`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`STATEMENT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`block`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`request`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`DGrS#qU8NTPeE9dE?AWI`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`mutation`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`with_statement`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`false`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`mutation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`WITH_STATEMENT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`FALSE`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`LOG`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`value`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`URL`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
              `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`shadow`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`nPN(_waF.y?1I\`,u8Qu]`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
                `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`field`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`TEXT`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`http://192.168.2.117/param.cgi?cmd=preset`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-act=goto`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-number=0`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-usr=admin`}<span parentName="code" {...{
            "className": "token entity named-entity",
            "title": "&"
          }}>{`&amp;`}</span>{`-pwd=instar`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`field`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
              `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`shadow`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`block`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`statement`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`block`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`next`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`block`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`xml`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`In the ioBroker Vis you can now define a `}<strong parentName="p">{`HTML Bool`}</strong>{` button for the object you created and add text the button should show when the value of the object is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/613add57cb36903071a7f39750303c09/e2e58/ioBroker_und_INSTAR_CGI_Befehle_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/0lEQVQI1wH0AAv/AOHh4ePj4+/v77a2tl9fX2RkZGNkZGZmZmVkZGxsbG1tbW1tbW5tbcrLy97h4d7h4eXn5+fp6fT4+PX29gD09PTv7+////+ioqITExMfHx8bGxsUFBQTExMXFxccHBwbGxscHBy3sLDd0NHo3d3t4eHs4ODr39/r4+MA9/f35+fn////rKurIiAgKCYmOzw8d3d3eHh4UFBRJSUlKSoqKCYnwayt9+7v8eXm4dLS4tLT5tna8djZAPT09Ofn5/7+/sSIi4MYHG4fIURDRKGhoaOjo2RkZB8fHycnJyQiIsOsrfbt7uvi49/V193U1Ofh4PLZ27g5jv2y1p/6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/613add57cb36903071a7f39750303c09/e4706/ioBroker_und_INSTAR_CGI_Befehle_06.avif 230w", "/en/static/613add57cb36903071a7f39750303c09/d1af7/ioBroker_und_INSTAR_CGI_Befehle_06.avif 460w", "/en/static/613add57cb36903071a7f39750303c09/7f308/ioBroker_und_INSTAR_CGI_Befehle_06.avif 920w", "/en/static/613add57cb36903071a7f39750303c09/c986f/ioBroker_und_INSTAR_CGI_Befehle_06.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/613add57cb36903071a7f39750303c09/a0b58/ioBroker_und_INSTAR_CGI_Befehle_06.webp 230w", "/en/static/613add57cb36903071a7f39750303c09/bc10c/ioBroker_und_INSTAR_CGI_Befehle_06.webp 460w", "/en/static/613add57cb36903071a7f39750303c09/966d8/ioBroker_und_INSTAR_CGI_Befehle_06.webp 920w", "/en/static/613add57cb36903071a7f39750303c09/b8544/ioBroker_und_INSTAR_CGI_Befehle_06.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/613add57cb36903071a7f39750303c09/81c8e/ioBroker_und_INSTAR_CGI_Befehle_06.png 230w", "/en/static/613add57cb36903071a7f39750303c09/08a84/ioBroker_und_INSTAR_CGI_Befehle_06.png 460w", "/en/static/613add57cb36903071a7f39750303c09/c0255/ioBroker_und_INSTAR_CGI_Befehle_06.png 920w", "/en/static/613add57cb36903071a7f39750303c09/e2e58/ioBroker_und_INSTAR_CGI_Befehle_06.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/613add57cb36903071a7f39750303c09/c0255/ioBroker_und_INSTAR_CGI_Befehle_06.png",
              "alt": "ioBroker und INSTAR CGI Befehle",
              "title": "ioBroker und INSTAR CGI Befehle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><img parentName="p" {...{
        "src": "/en/83d52833877f69d5e334bfa8384ac1e7/ioBroker_und_INSTAR_CGI_Befehle_07.gif",
        "alt": "ioBroker und INSTAR CGI Befehle"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      